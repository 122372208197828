<template>
  <header class="header">
    <BackIcon class="header__back" v-if="hasBack" @click="goBack" v-show="getPredefinedSource" />
    <img class="header__logo" v-if="hasLogo" :src="logo" alt="">
    <CloseIcon
      class="header__close"
      @click="closeApp()"
      v-if="hasClose"
      v-show="!getHeaderCloseButton" />
  </header>
</template>

<script>
import CloseIcon from './icons/CloseIcon';
import BackIcon from './icons/BackIcon';

export default {
  name: 'Header',
  components: {
    CloseIcon,
    BackIcon,
  },
  props: {
    title: {
      type: String,
      default: 'Rollee Ride',
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
    hasBack: {
      type: Boolean,
      default: false,
    },
    hasLogo: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasConnected() {
      return this.$store.state.connectedSources.length > 0;
    },
    logo() {
      if (this.$store.state.queryConfig) {
        return decodeURIComponent(this.$store.state.config.partner_logo);
      }
      return this.$store.state.config.partner_logo;
    },
    getHeaderCloseButton() {
      return this.$store.state.config.remove_close_button === 'true';
    },
    getPredefinedSource() {
      return !this.$store.state.config.datasource;
    },
  },
  methods: {
    closeApp() {
      this.$store.commit('SET_CLOSE_MODAL_ENABLED', true);
    },
    goBack() {
      this.$emit('goBack', true);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: grid;
  align-content: center;
  width: 100%;
  height: $header-height;
  position: relative;
  justify-content: center;

  &__title {
    text-align: center;
    color: $text-color-dark;
    font-size: $header-title-font-size;
  }

  &__close {
    position: absolute;
    top: 0;
    right: -16px;
  }

  &__back {
    position: absolute;
    top: 26px;
    left: 0px;
  }

  &__logo {
    height: 32px;
    max-height: 32px;
  }
}
</style>
