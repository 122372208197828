<template>
  <div class="page page--code" :class="{'page--remove': removeModal}">
    <Header :title="'Rollee Ride'" :hasBack="true" :hasClose="true" />
    <div class="page__content">
      <h2>
        <Translate :translate="'code'"/>
      </h2>
      <div class="input__wrapper">
        <textarea
          v-model="code"
          data-gramm="false"
          :placeholder="codePlaceholder"
          @keydown.enter.prevent
          autofocus
          rows="0">
        </textarea>
      </div>
      <div class="page__actions">
        <button @click="submit" class="button button--primary button--block">
          <Translate :translate="'send'"/>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';

export default {
  name: 'CodeModal',
  components: {
    Header,
  },
  data() {
    return {
      code: undefined,
      removeModal: false,
    };
  },
  computed: {
    ...mapGetters(['getFetchUUID', 'translations']),
    codePlaceholder() {
      return this.$store.state.translations.codePlaceholder;
    },
  },
  methods: {
    submit() {
      this.removeModal = true;
      setTimeout(() => {
        this.$store.commit('SET_CODE_MODAL', false);
        this.$store.dispatch('sendConfirmationCode', this.code).then((res) => {
          let description = this.$store.state.errorConnectingDataSource;
          if (res['error-std'] && this.translations.errors[res['error-std']]) {
            description = this.translations.errors[res['error-std']];
          } else if (res['error-info']) {
            description = res['error-info'];
          }
          switch (res.status) {
            case 'error':
              this.$store.commit('SET_ERROR', {
                name: 'Error',
                description,
              });
              this.$router.push({ name: 'Login' });
              break;
            case 'complete':
              this.$store.dispatch('getConnectedSources');
              this.$router.push({ name: 'Connected' });
              break;
            case 'connected':
              this.$store.dispatch('getConnectedSources');
              this.$router.push({ name: 'Connected' });
              break;
            case 'success':
              this.$store.dispatch('getConnectedSources');
              this.$router.push({ name: 'Connected' });
              break;
            default:
              break;
          }
        });
      }, 250);
    },
  },
};

</script>
<style lang="scss" scoped>
textarea {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  border: transparent;
  outline: 0;
  cursor: text;
  resize: none;
  font-family: inherit;
  padding: inherit;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 4px;
  font-weight: 900;
}
.input__wrapper {
  margin-top: 22px;
  min-height: 32px;
}
</style>
