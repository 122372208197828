<template>
  <div class="searchField__wrapper">
    <SearchIcon />
    <input type="text" ref="search" @input="emitVal()" :placeholder="placeholder" />
    <label @click="focused" v-if="!removeLabel">
      <Translate :translate="'inputSearchPlaceholder'" />
    </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchIcon from './icons/SearchIcon';

export default {
  name: 'SearchField',
  props: ['dataPlaceholder'],
  components: {
    SearchIcon,
  },
  emits: {
    update: null,
  },
  data() {
    return {
      removeLabel: this.dataPlaceholder,
    };
  },
  computed: {
    ...mapGetters([
      'getSearchInputFocused',
      'translations',
    ]),
    placeholder() {
      if (typeof this.dataPlaceholder === 'boolean' && this.dataPlaceholder === false) {
        return '';
      }
      return this.dataPlaceholder || this.translations.inputSearchPlaceholder;
    },
  },
  methods: {
    focused() {
      this.removeLabel = true;
      const el = this.$refs.search;
      el.focus();
    },
    emitVal() {
      this.$emit('update', this.$refs.search.value);
    },
  },
  watch: {
    getSearchInputFocused(val) {
      if (val) {
        this.focused();
        this.$store.commit('SET_SEARCH_INPUT_FOCUSED', false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.searchField {

  &__wrapper {
    background: $input-background-color;
    padding: 12px 12px 12px 12px;
    border-radius: 9px;
    position: relative;
    cursor: text;
    input {
      width: 100%;
      border: none;
      background: transparent;
      font-size: 14px;
      padding-left: 32px;
      box-sizing: border-box;
      font-family: 'Poppins', sans-serif;
      &:focus {
        outline: none;
      }
    }
    svg {
      position: absolute;
      height: 22px;
      padding-left: 4px;
    }
    label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      line-height: 44px;
      cursor: text;
    }
  }
}
</style>
