<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="256.000000pt" height="256.000000pt" viewBox="0 0 256.000000 256.000000"
      preserveAspectRatio="xMidYMid meet" fill="#aaa">

      <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
      stroke="none" fill="#aaa">
      <path d="M1651 2218 c-64 -14 -135 -88 -149 -154 -12 -57 -3 -114 28 -172 25
      -45 25 -56 0 -110 -28 -63 -63 -74 -160 -54 -226 46 -456 -90 -530 -314 -25
      -73 -56 -104 -106 -104 -54 0 -80 16 -123 73 -49 67 -94 96 -159 104 -190 22
      -311 -195 -190 -342 82 -99 244 -100 323 -1 74 92 79 96 137 96 70 0 94 -18
      113 -83 35 -121 126 -231 235 -285 106 -53 167 -59 357 -36 50 6 54 5 82 -25
      45 -48 50 -92 17 -158 -66 -136 -3 -282 136 -313 60 -14 110 -5 165 29 85 52
      118 181 71 271 -33 62 -101 107 -176 115 -79 9 -109 22 -132 60 -32 52 -26 86
      26 147 74 84 115 181 121 282 9 138 -23 232 -117 345 -57 68 -63 102 -30 156
      23 37 53 51 127 59 141 14 229 134 194 265 -16 59 -44 96 -94 125 -51 29 -107
      38 -166 24z m-249 -675 c173 -77 224 -313 99 -455 -54 -61 -120 -92 -208 -96
      -60 -3 -80 0 -125 20 -76 35 -112 70 -147 140 -80 160 0 348 171 404 56 18
      152 12 210 -13z" fill="#0033cc"/>
      </g>
  </svg>
</template>
