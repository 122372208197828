import types from './mutationTypes';

export default {
  [types.SET_SOURCES](state, value) {
    state.sources = value;
  },
  [types.SET_ERROR](state, value) {
    state.error = value;
  },
  [types.SET_SELECTED_SOURCE](state, value) {
    state.selectedSource = value;
  },
  [types.SET_CONNECTED_SOURCES](state, value) {
    state.connectedSources = [];
    state.connectedSources = value;
  },
  [types.SET_SUGGESTED_SOURCES](state, value) {
    state.suggestedSources = value;
  },
  [types.SET_CONNECT_LOGO_POSITION](state, value) {
    state.connectLogoPosition = value;
  },
  [types.SET_SEARCH_INPUT_FOCUSED](state, value) {
    state.searchInputFocused = value;
  },
  [types.SET_AUTH](state, value) {
    state.authenticated = value;
  },
  [types.SET_LOGIN_CREDENTIALS](state, value) {
    state.loginCredentials = value;
  },
  [types.SET_CLOSE_MODAL_ENABLED](state, value) {
    state.closeModalEnabled = value;
  },
  [types.SET_APP_UUID](state, value) {
    state.config.uuid = value;
  },
  [types.SET_FETCH_UUID](state, value) {
    state.fetchUUID = value;
  },
  [types.SET_COUNTRY_MODAL](state, value) {
    state.countryModal = value;
  },
  [types.SET_COUNTRY_SELECTED](state, value) {
    state.countrySelected = value;
  },
  [types.SET_CODE_MODAL](state, value) {
    state.codeModal = value;
  },
  [types.SET_WAITING_FETCH_UUID](state, value) {
    state.waitingFetchUUID = value;
  },
  [types.SET_REFRESHED_UUID](state, value) {
    state.refreshed = value;
  },
  [types.SET_FACEBOOK_MODAL](state, value) {
    state.facebookModal = value;
  },
};
