<template>
<div class="back">
  <svg class="mt-1" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.31778 1L1 7.20408M1 7.20408L6.31778 13.4082M1 7.20408H17"
      stroke="#121212"
      stroke-width="1.30612"
      stroke-linecap="round"
      stroke-linejoin="round">
    </path>
  </svg>
  <p>BACK</p>
</div>
</template>

<script>
export default {
  name: 'BackIcon',
};
</script>

<style lang="scss" scoped>
.back {
  cursor: pointer;
  transition: $transition;
  &:hover {
    transform: translateX(-2px)
  }
  svg {
    width: 18px;
    height: 15px;
    margin-top: -1px;
  }
  p {
    display: inline;
    vertical-align: top;
    line-height: 16px;
    margin-left: 6px;
    transform: translate(5px, -3px)
  }
}
</style>
