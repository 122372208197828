<!-- eslint-disable -->
<template>
  <svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   width="304"
   height="121"
   viewBox="0 0 304 121"
   fill="none"
   version="1.1"
   id="svg106"
   sodipodi:docname="legal-header.svg"
   inkscape:version="1.0.2 (e86c870879, 2021-01-15)">
  <rect
     width="304"
     height="121"
     fill="#E5E5E5"
     id="rect2" />
  <rect
     width="428"
     height="926"
     transform="translate(-61 -121)"
     fill="white"
     id="rect4" />
  <path
     d="M48 52H258"
     stroke="#F2F2F2"
     stroke-width="2"
     id="path6" />
  <g
     filter="url(#filter0_d)"
     id="g12">
    <path
       d="M189.145 28.223C189.121 26.3478 187.678 24.7988 185.811 24.6275C170.296 23.1763 157.634 14.8848 153.46 11.8519C152.588 11.216 151.405 11.216 150.533 11.8519C146.367 14.8848 133.706 23.1763 118.191 24.6275C116.324 24.7988 114.889 26.3478 114.856 28.223C114.669 40.4604 116.634 82.0073 151.063 91.2528C151.675 91.4158 152.319 91.4158 152.93 91.2528C187.36 82.0156 189.324 40.4603 189.145 28.223Z"
       fill="white"
       id="path8" />
    <path
       d="M188.645 28.2295L188.645 28.2303C188.734 34.3242 188.288 47.7019 183.546 60.6915C178.808 73.6712 169.805 86.2073 152.801 90.7697C152.274 90.9101 151.72 90.9102 151.193 90.7699C134.188 86.2036 125.185 73.6672 120.448 60.6884C115.707 47.7003 115.263 34.3251 115.356 28.2312C115.385 26.6106 116.624 25.2733 118.236 25.1254L118.237 25.1253C133.887 23.6615 146.637 15.3065 150.827 12.2562L150.828 12.256C151.525 11.748 152.469 11.748 153.165 12.256L153.166 12.2564C157.364 15.3064 170.114 23.6615 185.764 25.1253L185.765 25.1254C187.379 25.2735 188.624 26.6123 188.645 28.2295Z"
       stroke="#F2F2F2"
       id="path10" />
  </g>
  <g
     filter="url(#filter1_d)"
     id="g18">
    <circle
       cx="49"
       cy="51"
       r="29"
       fill="white"
       id="circle14" />
    <circle
       cx="49"
       cy="51"
       r="28.5"
       stroke="#F2F2F2"
       id="circle16" />
  </g>
  <g
     filter="url(#filter2_d)"
     id="g24">
    <circle
       cx="255"
       cy="51"
       r="29"
       fill="white"
       id="circle20" />
    <circle
       cx="255"
       cy="51"
       r="28.5"
       stroke="#F2F2F2"
       id="circle22" />
  </g>
  <rect
     x="251"
     y="36"
     width="9"
     height="2"
     rx="1"
     fill="black"
     id="rect26" />
  <rect
     x="247.5"
     y="36.5"
     width="16"
     height="28"
     rx="2.5"
     stroke="black"
     id="rect28" />
  <g
     clip-path="url(#clip2)"
     id="g42">
    <path
       d="M48.5 51.9551C41.5894 51.9551 37.7834 55.2243 37.7834 61.1607C37.7834 61.6243 38.1592 62.0001 38.6229 62.0001H58.3771C58.8408 62.0001 59.2165 61.6243 59.2165 61.1607C59.2166 55.2246 55.4107 51.9551 48.5 51.9551ZM39.4928 60.3213C39.823 55.8827 42.8491 53.6339 48.5 53.6339C54.151 53.6339 57.1771 55.8827 57.5075 60.3213H39.4928Z"
       fill="black"
       id="path38" />
    <path
       d="M48.5 39C45.3256 39 42.9319 41.4419 42.9319 44.6798C42.9319 48.0125 45.4297 50.7236 48.5 50.7236C51.5703 50.7236 54.0681 48.0125 54.0681 44.6801C54.0681 41.4419 51.6744 39 48.5 39ZM48.5 49.045C46.3553 49.045 44.6107 47.0869 44.6107 44.6801C44.6107 42.3616 46.2464 40.6788 48.5 40.6788C50.7175 40.6788 52.3893 42.3988 52.3893 44.6801C52.3893 47.0869 50.6447 49.045 48.5 49.045Z"
       fill="black"
       id="path40" />
  </g>
  <defs
     id="defs104">
    <filter
       id="filter0_d"
       x="94.8457"
       y="0.375"
       width="114.309"
       height="120"
       filterUnits="userSpaceOnUse"
       color-interpolation-filters="sRGB">
      <feFlood
         flood-opacity="0"
         result="BackgroundImageFix"
         id="feFlood44" />
      <feColorMatrix
         in="SourceAlpha"
         type="matrix"
         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
         result="hardAlpha"
         id="feColorMatrix46" />
      <feOffset
         dy="9"
         id="feOffset48" />
      <feGaussianBlur
         stdDeviation="10"
         id="feGaussianBlur50" />
      <feComposite
         in2="hardAlpha"
         operator="out"
         id="feComposite52" />
      <feColorMatrix
         type="matrix"
         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
         id="feColorMatrix54" />
      <feBlend
         mode="normal"
         in2="BackgroundImageFix"
         result="effect1_dropShadow"
         id="feBlend56" />
      <feBlend
         mode="normal"
         in="SourceGraphic"
         in2="effect1_dropShadow"
         result="shape"
         id="feBlend58" />
    </filter>
    <filter
       id="filter1_d"
       x="0"
       y="6"
       width="98"
       height="98"
       filterUnits="userSpaceOnUse"
       color-interpolation-filters="sRGB">
      <feFlood
         flood-opacity="0"
         result="BackgroundImageFix"
         id="feFlood61" />
      <feColorMatrix
         in="SourceAlpha"
         type="matrix"
         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
         result="hardAlpha"
         id="feColorMatrix63" />
      <feOffset
         dy="4"
         id="feOffset65" />
      <feGaussianBlur
         stdDeviation="10"
         id="feGaussianBlur67" />
      <feComposite
         in2="hardAlpha"
         operator="out"
         id="feComposite69" />
      <feColorMatrix
         type="matrix"
         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
         id="feColorMatrix71" />
      <feBlend
         mode="normal"
         in2="BackgroundImageFix"
         result="effect1_dropShadow"
         id="feBlend73" />
      <feBlend
         mode="normal"
         in="SourceGraphic"
         in2="effect1_dropShadow"
         result="shape"
         id="feBlend75" />
    </filter>
    <filter
       id="filter2_d"
       x="206"
       y="6"
       width="98"
       height="98"
       filterUnits="userSpaceOnUse"
       color-interpolation-filters="sRGB">
      <feFlood
         flood-opacity="0"
         result="BackgroundImageFix"
         id="feFlood78" />
      <feColorMatrix
         in="SourceAlpha"
         type="matrix"
         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
         result="hardAlpha"
         id="feColorMatrix80" />
      <feOffset
         dy="4"
         id="feOffset82" />
      <feGaussianBlur
         stdDeviation="10"
         id="feGaussianBlur84" />
      <feComposite
         in2="hardAlpha"
         operator="out"
         id="feComposite86" />
      <feColorMatrix
         type="matrix"
         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
         id="feColorMatrix88" />
      <feBlend
         mode="normal"
         in2="BackgroundImageFix"
         result="effect1_dropShadow"
         id="feBlend90" />
      <feBlend
         mode="normal"
         in="SourceGraphic"
         in2="effect1_dropShadow"
         result="shape"
         id="feBlend92" />
    </filter>
    <clipPath
       id="clip0">
      <rect
         width="36.3312"
         height="36.2772"
         fill="white"
         transform="translate(130.18 33.3799)"
         id="rect95" />
    </clipPath>
    <clipPath
       id="clip1">
      <rect
         width="981.25"
         height="981.25"
         fill="white"
         transform="translate(-334 -435)"
         id="rect98" />
    </clipPath>
    <clipPath
       id="clip2">
      <rect
         width="23"
         height="23"
         fill="white"
         transform="translate(37 39)"
         id="rect101" />
    </clipPath>
  </defs>
</svg>

</template>
