import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import Terms from '../views/Terms';
import Search from '../views/Search';
import Login from '../views/Login';
import Fetch from '../views/Fetch';
import Connected from '../views/Connected';
import Success from '../views/Success';
import Contact from '../views/Contact';
import Error from '../views/Error';

const routes = [
  {
    path: '/:id?',
    name: 'Home',
    component: () => import('../views/Home'),
    meta: {
      index: 0,
      animation: true,
    },
  },
  {
    path: '/:id/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      index: 1,
      animation: true,
      auth: false,
    },
  },
  {
    path: '/:id/search',
    name: 'Search',
    component: Search,
    meta: {
      index: 2,
      animation: true,
      auth: false,
    },
    params: true,
  },
  {
    path: '/:id/login',
    name: 'Login',
    component: Login,
    meta: {
      index: 3,
      animation: true,
      auth: false,
    },
  },
  {
    path: '/:id/fetch',
    name: 'Fetch',
    component: Fetch,
    meta: {
      index: 4,
      animation: false,
      auth: true,
    },
  },
  {
    path: '/:id/connected',
    name: 'Connected',
    component: Connected,
    meta: {
      index: 5,
      animation: true,
      auth: false,
    },
  },
  {
    path: '/:id/success',
    name: 'Success',
    component: Success,
    meta: {
      index: 6,
      animation: true,
      auth: true,
    },
  },
  {
    path: '/:id/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      index: 7,
      animation: true,
      auth: false,
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    meta: {
      index: 8,
      animation: true,
      auth: false,
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/error',
  },
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { id } = to.params;
  store.commit('SET_APP_UUID', id);
  document.title = `Rollee Connect - ${to.name}` || 'Rollee Connect';

  const REFRESHED = typeof from.meta.index === 'undefined';

  const urlParams = new URLSearchParams(window.location.search);
  const Qparams = Object.fromEntries(urlParams.entries());

  if (REFRESHED && to.meta.index === 4) {
    return next();
  }

  if (REFRESHED && to.meta.index === 5) {
    return next();
  }

  if (Qparams.skip_first_page === 'true' && to.meta.index === 0) {
    return next({ name: 'Terms', params: { id }, query: to.query });
  }

  if (store.state.config.skip_datasources_page === 'true' && to.meta.index === 5) {
    return next({ name: 'Success', params: { id }, query: to.query });
  }

  if (to.meta.index > 2 && !store.state.authenticated && REFRESHED) {
    return next({ name: 'Home', params: { id }, query: to.query });
  }

  if (store.state.config.skip_first_page === 'true' && to.meta.index === 0) {
    return next({ name: 'Terms', params: { id }, query: to.query });
  }

  if (from.name === 'Home' && to.name === 'Login') {
    if (window.CONFIG.partner_name === 'rodeo') {
      return next();
    }
    return next({ name: 'Home', params: { id }, query: to.query });
  }

  if (from.name === 'Home' && to.name === 'Search') {
    if (window.CONFIG.product === 'demo') {
      return next();
    }
    return next({ name: 'Home', params: { id }, query: to.query });
  }

  if (from.name === undefined && to.name === 'Search') {
    if (window.CONFIG.product === 'demo') {
      return next();
    }
    return next({ name: 'Home', params: { id }, query: to.query });
  }

  return next();
});

export default router;
