<template>
  <div id="app__wrapper" class="app__wrapper">
    <router-view v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
    <CloseModal />
    <CodeModal v-if="getCodeModal" />
    <FacebookModal />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import CloseModal from '@/components/CloseModal';
import CodeModal from '@/components/CodeModal';
import FacebookModal from '@/components/FacebookModal';
import Data from '@/api/data';

export default {
  name: 'App',
  components: {
    CloseModal,
    CodeModal,
    FacebookModal,
  },
  data() {
    return {
      transitionName: undefined,
      firstLoad: false,
      authPages: [
        'Fetch',
        'Connect',
      ],
    };
  },
  computed: {
    isMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];
      return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
    },
    ...mapGetters(['getCodeModal', 'sources']),
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE || 'Rollee Connect';
    this.initWoker();
    this.getSources().then(() => {
      this.sources.sources.forEach((source) => {
        if (source['last-fetch'] && typeof source['last-fetch'] === 'object') {
          if (source['last-fetch'].uuid && !source['last-fetch'].status) {
            this.$store.commit('SET_SELECTED_SOURCE', source);
            this.$store.commit('SET_REFRESHED_UUID', source['last-fetch'].uuid);
            this.$store.commit('SET_AUTH', true);
            this.$router.push({
              name: 'Fetch',
            });
          }
          if (source.ID === window.CONFIG.datasource) {
            this.$store.commit('SET_SELECTED_SOURCE', source);
          }
        }
      });

      if (window.CONFIG.product === 'demo') {
        this.$store.state.connectedSources = this.$store.state.sources['sources-connected'];
        this.$store.state.suggestedSources = this.$store.state.sources['sources-suggested'];
      }
    });
  },
  methods: {
    ...mapActions(['getSources']),
    setTransition(to, from) {
      if (this.firstLoad && to.meta.animation) {
        this.transitionName = to.meta.index > from.meta.index ? "next" : "prev";
      } else {
        this.transitionName = '';
      }
      this.firstLoad = true;
    },
    initWoker() {
      if (window.Worker) {
        const worker = new Worker(`${process.env.VUE_APP_BASE_URL}js/session.worker.js`);
        window.rolleeWorker = worker;
        worker.addEventListener('message', this.workerResponseHandler);
      }
      this.$router.isReady().then(() => {
        if (process.env.VUE_APP_ENABLE_TRACKING === 'true') {
          Data.analytics({
            type: 'user',
            data: {
              userName: '',
              status: '',
              successRate: 0,
              datasourcesCount: 0,
              userUUID: this.$route.params.id,
              env: process.env.NODE_ENV,
              device: this.isMobile ? 'mobile' : 'desktop',
            },
          });
        }
      });
    },
    workerResponseHandler(e) {
      switch (e.data.action) {
        case 'getSessionDuration':
          if (e.data.screenName) {
            let STATUS;
            let STATUS_DESCRIPTION;
            if (e.data.screenID === 4) {
              STATUS = 'success';
              if (this.$store.state.error) {
                STATUS = 'error';
                STATUS_DESCRIPTION = this.$store.state.error.description;
              }
            }
            const payload = {
              type: 'event',
              data: {
                duration: e.data.duration,
                screenID: e.data.screenID,
                screenName: e.data.screenName,
                userUUID: this.$store.state.config.uuid,
                datasourceName: this.$store.state.selectedSource?.name || '',
                datasourceURL: this.$store.state.selectedSource?.url || '',
                status: STATUS || '',
                statusDescription: STATUS_DESCRIPTION || '',
              },
            };
            if (process.env.VUE_APP_ENABLE_TRACKING === 'true') {
              Data.analytics(payload);
            }
          }
          break;

        case 'fetchingStarted':
          this.$store.commit('SET_ERROR', {
            name: 'Error',
            description: this.$store.state.errorConnectingDataSource,
          });
          this.$router.push({
            name: 'Login',
          });
          break;
        default:
          break;
      }
    },
  },
  watch: {
    $route(to, from) {
      window.top.postMessage(to.name, "*");

      this.setTransition(to, from);
      if (from.name) {
        window.rolleeWorker.postMessage({
          action: 'getSessionDuration',
          page: `${from.name} => ${to.name}`,
          screenID: from.meta.index,
        });
      }
      if (to.name === 'Fetch') {
        window.rolleeWorker.postMessage({
          action: 'fetchingStarted',
        });
      }
    },
  },
};
</script>
<style lang="scss">

html {
  height: calc(100vh - calc(100vh - 100%));
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: $text-color-default;
  height: calc(100vh - calc(100vh - 100%));
}

* {
  margin: 0;
}

#app {
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  background: $wrapper-background-color;
  @include respond-below(md) {
    display: block
  }
}

.app {
  &__wrapper {
    position: relative;
    width: $app-width;
    height: $app-height;
    max-height: 100%;
    background: $app-background-color;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: $app-border-radius;
    margin: 12px;
    overflow: hidden;
    @include respond-below(md) {
      width: 100%;
      height: 100%;
      border-radius: initial;
      margin: 0px;
    }
    @include respond-below-height(md) {
      overflow: auto;
      max-height: 100vh;
    }
  }
}
</style>
