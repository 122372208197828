<template>
  <div class="page page--connect" :class="{ focused: searchFocused }">
    <Header
      title="'Rollee Ride'"
      :hasClose="true"
      :hasBack="getConnectedSources.length > 0"
      @goBack="goBack"
    />
    <div class="page__content">
      <div class="page__search" v-if="!searchFocused">
        <h1 class="page__title" :class="{ focused: removeSearch }">
          <Translate :translate="'connectPageTitle'" />
        </h1>
        <SearchField
          class="page__search-input"
          :class="{ focused: removeSearch }"
          :dataPlaceholder="false"
          @click="searchFocus()"
        />
      </div>
      <div class="page__connect" v-else
          :class="{'page__connect--tworows': !showFilters || !getConfig.search_page_categories}">
        <div class="page__input">
          <SearchField
            class="page__search-input page__search-input--faster"
            :dataPlaceholder="getConfig.search_page_title"
            @update="searchValue"
          />
        </div>
        <div class="page__filters" v-if="showFilters" v-show="getConfig.search_page_categories">
          <button
            class="button--small button--secondary"
            :class="{'button--active': selectedCategory === 'all'}"
            @click="getSourceCategory('all')">
              All
          </button>
          <button
            class="button--small button--secondary button--has-icon"
            :class="{'button--active': selectedCategory === 'gig'}"
            @click="getSourceCategory('gig')">
              <img src="@/assets/filter-gig.png" alt="">
              GIG
          </button>
          <button
            class="button--small button--secondary button--has-icon"
            :class="{'button--active': selectedCategory === 'payroll'}"
            @click="getSourceCategory('payroll')">
              <img src="@/assets/filter-payroll.png" alt="">
              Payroll
          </button>
          <button
            class="button--small button--secondary button--has-icon"
            :class="{'button--active': selectedCategory === 'freelance'}"
            @click="getSourceCategory('freelance')">
              <img src="@/assets/filter-freelance.png" alt="">
              Freelance
          </button>
        </div>
        <div class="page__list">
          <ul v-if="filteredSource.length">
            <li
              v-for="(source, index) in filteredSource"
              :key="source.name"
              @click="connect(source)"
              :style="getAnimationDelay(index)">
              <div class="page__list-icon">
                <img v-if="getAvatar(source)" :src="getAvatar(source)" :alt="source.name" />
                <div v-else class="page__list-default"></div>
              </div>
              <div class="page__list-name">
                <h2>{{ source.name }}</h2>
              </div>
            </li>
            <li @click="contact()">
              <div class="page__list-icon">
                <div></div>
              </div>
              <div class="page__list-name page__list-name--smaller">
                <h2><Translate :translate="'dontSeeCompany'"/></h2>
                <p><Translate :translate="'clickToContinue'"/></p>
              </div>
            </li>
          </ul>
          <div class="page__list--empty" v-else>
            <div class="page__list">
              <ul>
                <li @click="contact()">
                  <div class="page__list-icon">
                    <div></div>
                  </div>
                  <div class="page__list-name page__list-name--smaller">
                    <h2><Translate :translate="'dontSeeCompany'"/></h2>
                    <p><Translate :translate="'clickToContinue'"/></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Header from '@/components/Header';
import SearchField from '../components/SearchField';

export default {
  name: 'Search',
  components: {
    Header,
    SearchField,
  },
  params: ['searchFocus'],
  data() {
    return {
      searchFocused: this.$route.params.searchFocus === 'true',
      removeSearch: false,
      data: [],
      searchWord: undefined,
      selectedCategory: 'all',
    };
  },
  computed: {
    ...mapGetters(['sources', 'getConnectedSources', 'getConfig']),
    categorySources() {
      if (this.selectedCategory === 'all') {
        return this.sources.sources;
      }
      return this.sources.sources.filter((source) => source.category === this.selectedCategory);
    },
    showFilters() {
      if (typeof this.sources !== 'undefined') {
        return this.sources?.sources[0].category !== undefined;
      }
      return false;
    },
    filteredSource() {
      if (this.sources.sources !== null) {
        // eslint-disable-next-line
        const reducedSources = this.categorySources.filter((source) => {
          // eslint-disable-next-line
          return !this.getConnectedSources.some((connectedSource) => connectedSource.name === source.name);
        });

        if (this.searchWord) {
          // eslint-disable-next-line
          return reducedSources.filter((source) => source.name.toLowerCase().includes(this.searchWord.toLowerCase()));
        }
        // filter reducedSources by last-fetch peoperty valu
        return reducedSources.filter((source) => {
          if (source['last-fetch'] && source['last-fetch'].status !== undefined) {
            if (source['last-fetch'].status === 'complete'
                || source['last-fetch'].status === 'connected') {
              return false;
            }
          }
          return true;
        });
      }
      return [];
    },
  },
  mounted() {
    this.getSources();
    this.$store.commit("SET_ERROR", null);
    const timeout = this.searchFocused ? 100 : 2000;
    setTimeout(() => {
      this.searchFocus();
    }, timeout);
  },
  methods: {
    ...mapActions(['getSources']),
    searchFocus() {
      this.removeSearch = true;
      setTimeout(() => {
        this.searchFocused = true;
      }, 1200);
      setTimeout(() => {
        this.$store.commit('SET_SEARCH_INPUT_FOCUSED', true);
      }, 1300);
    },
    getAnimationDelay(index) {
      return {
        'animation-delay': `${index * 0.14}s`,
      };
    },
    connect(source) {
      this.$store.commit('SET_SELECTED_SOURCE', source);
      this.$router.push({
        name: 'Login',
      });
    },
    searchValue(payload) {
      this.searchWord = payload;
    },
    contact() {
      this.$router.push({
        name: 'Contact',
      });
    },
    goBack() {
      this.$router.push({
        name: 'Connected',
      });
    },
    getSourceCategory(selection) {
      this.selectedCategory = selection;
    },
    getAvatar(data) {
      return `${process.env.VUE_APP_API_CONNECT_PATH}${data.avatar}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  &__search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    &-input {
      animation: fadeInUp 2s forwards normal cubic-bezier(.66,-0.15,.14,1.16);
      animation-delay: 0s;
      &.focused {
        animation: fadeOutUp .7s forwards normal cubic-bezier(.66,-0.15,.14,1.16);
        animation-delay: 0s;
      }
      &--faster {
        animation: fadeInUp .3s forwards normal cubic-bezier(.66,-0.15,.14,1.16);
        animation-delay: 0s;
      }
    }
  }
  &__title {
    animation: scaleDownGoUp 2.5s forwards normal;
    animation-delay: 1s;
    font-size: 1.7em !important;
    padding: 0 12px;
    text-align: center;
    width: 100% !important;
    padding: 0;
    margin: 0 !important;
    &.focused {
      animation: scaleUpFadeOut 1s forwards normal;
    }
  }
  &.focused {
    align-content: stretch;
  }
}
</style>
