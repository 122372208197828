export default {
  translations: (state) => state.translations,
  sources: (state) => state.sources,
  getSelectedSource: (state) => state.selectedSource,
  getConnectedSources: (state) => state.connectedSources,
  getSuggestedSources: (state) => state.suggestedSources,
  getConnectLogoPosition: (state) => state.connectLogoPosition,
  getSearchInputFocused: (state) => state.searchInputFocused,
  getCloseModalEnabled: (state) => state.closeModalEnabled,
  getFacebookModal: (state) => state.facebookModal,
  getLoginCredentials: (state) => state.loginCredentials,
  getError: (state) => state.error,
  getCountryModal: (state) => state.countryModal,
  getCountrySelected: (state) => state.countrySelected,
  getCodeModal: (state) => state.codeModal,
  getFetchUUID: (state) => state.fetchUUID,
  getConfig: (state) => state.config,
  getRefreshed: (state) => state.refreshed,
  getCountries: (state) => state.dialCodes,
};
