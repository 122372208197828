<template>
  <div class="fetch__wrapper" v-if="getSelectedSource">
    <div class="fetch__connect" v-if="connect">
      <div class="fetch__logo">
        <img
          v-if="getAvatar(getSelectedSource)"
          :src="getAvatar(getSelectedSource)"
          alt="Logo"
          :style="getPosition"
          :class="{ 'revent': hidden }" />
      </div>
      <div class="fetch__content" :style="getTextPosition">
        <div class="fetch__connecting" :class="{'animate': waitingMessage, 'hidden': hidden}">
          <h2>
            <div v-if="waitingMessage()">
              <p>{{ waitingMessage() }}</p>
            </div>

            <div v-else>
              <Translate :translate="'connecting'"/> {{ getSelectedSource.name }}
              <br />
              <Translate :translate="'pleaseWait'"/>
            </div>
          </h2>
        </div>
        <p class="fetch__quotes">
          <span>{{ randomQuote }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "Fetch",
  data() {
    return {
      animate: false,
      hidden: false,
      connect: true,
      randomQuote: this.getRandomQuote(),
      WAIT_FOR_PROPER_RESPONSE: null,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.getRefreshed) {
        this.waitingForLoopResponse(this.getRefreshed);
      } else if (this.getSelectedSource) {
        this.animate = false;
        this.connectToSource();
      } else {
        this.getConnectedSources().then(() => {
          this.$router.push({ name: 'Connected' });
        });
      }
    }, 500);
    setInterval(() => {
      this.randomQuote = this.getRandomQuote();
    }, 5000);
  },
  unmounted() {
    clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
  },
  computed: {
    ...mapGetters(['getSelectedSource', 'getConnectLogoPosition', 'getLoginCredentials', 'getError', 'translations', 'getRefreshed']),
    getPosition() {
      return {
        position: "absolute",
        top: this.getConnectLogoPosition ? `${this.getConnectLogoPosition.y}px` : "80px",
        left: this.getConnectLogoPosition ? `${this.getConnectLogoPosition.x}px` : "30px",
      };
    },
    getTextPosition: {
      get() {
        return {
          position: 'absolute',
          top: this.getConnectLogoPosition ? `${this.getConnectLogoPosition.y + this.getConnectLogoPosition.height + 20}px` : "20px",
        };
      },
    },
  },
  methods: {
    ...mapActions(['loopForStatus', 'getConnectedSources']),
    getRandomQuote() {
      const quotes = this.$store.getters.translations.waitMessages;
      const random = Math.floor(Math.random() * (quotes.length - 1));
      return quotes[random];
    },
    connectToSource() {
      this.$store.dispatch('connectToSource', this.getSelectedSource).then((res) => {
        if (res) {
          let description = this.$store.state.errorConnectingDataSource;
          if (res['error-std'] && this.translations.errors[res['error-std']]) {
            description = this.translations.errors[res['error-std']];
          } else if (res['error-info']) {
            description = res['error-info'];
          }
          // check object has property
          if (res.uuid) {
            this.waitingForLoopResponse(res.uuid);
          } else {
            switch (res.status) {
              case 'connected':
                this.$store.commit('SET_FETCH_UUID', res.uuid);
                this.$store.dispatch('getConnectedSources');
                if (this.$store.state.config.product === 'demo') {
                  setTimeout(() => {
                    this.$router.push({ name: 'Connected' });
                  }, 12000);
                } else {
                  clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
                  this.$router.push({ name: 'Connected' });
                }
                break;
              case 'code':
                clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
                this.$store.commit('SET_FETCH_UUID', res.uuid);
                this.$store.commit('SET_CODE_MODAL', true);
                break;
              case 'error':
                clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
                this.$store.commit('SET_ERROR', {
                  name: 'Error',
                  description,
                });
                this.gotoLogin();
                break;
              case 'complete':
                clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
                this.$store.dispatch('getConnectedSources');
                this.$router.push({ name: 'Connected' });
                break;

              default:
                this.$store.commit('SET_ERROR', {
                  name: 'OOOPS...',
                  description: 'Something went wrong',
                });
                this.gotoLogin();
                break;
            }
          }
        } else {
          this.animate = false;
          this.hidden = true;
          // this.connect = false;
          this.$store.commit('SET_ERROR', {
            name: 'OOOPS...',
            description: 'Something went wrong',
          });
        }
      });
    },
    // loop every 2 seconds
    waitingForLoopResponse(uuid) {
      this.$store.commit('SET_FETCH_UUID', uuid);
      this.WAIT_FOR_PROPER_RESPONSE = setInterval(() => {
        this.$store.dispatch('loopForStatus', uuid).then((res) => {
          // eslint-disable-next-line no-console
          if (navigator.onLine) {
            if (res.status === 'connected' || res.status === 'complete') {
              clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
              if (this.$store.state.config.product === 'demo') {
                setTimeout(() => {
                  this.$router.push({ name: 'Connected' });
                }, 12000);
              } else {
                this.$router.push({ name: 'Connected' });
              }
            } else if (res.status === 'error') {
              clearInterval(this.WAIT_FOR_PROPER_RESPONSE);

              let description = this.$store.state.errorConnectingDataSource;

              if (res['error-std'] && this.translations.errors[res['error-std']]) {
                description = this.translations.errors[res['error-std']];
              } else if (res['error-info']) {
                description = res['error-info'];
              }

              this.$store.commit('SET_ERROR', {
                name: 'Error',
                description,
              });
              this.gotoLogin();
            } else if (res.status === 'code') {
              this.$store.commit('SET_CODE_MODAL', true);
              clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
            } else if (res.status === 'complete') {
              clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
              this.$router.push({ name: 'Connected' });
            }
          } else {
            // eslint-disable-next-line no-console
            console.warn('We are offline');
          }
        });
      }, 2000);
    },
    gotoDatasources() {
      this.$router.push({ name: 'Search' });
    },
    gotoLogin() {
      this.$router.push({ name: 'Login' });
    },
    waitingMessage() {
      const loginFields = this.getSelectedSource['login-fields'];
      if (loginFields) {
        if (loginFields.find((field) => field.field === 'waitingMessage')) {
          return loginFields.find((field) => field.field === 'waitingMessage').label;
        }
      }
      return false;
    },
    getAvatar(data) {
      return `${process.env.VUE_APP_API_CONNECT_PATH}${data.avatar}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fetch {
  &__wrapper {
    position: relative;
    height: 100%;
  }
  &__logo {
    img {
      width: 60px;
      height: 60px;
      border-radius: 999px;
      animation: moveToCenter 1s ease forwards;
      &.revent {
        animation: moveBack 1s ease forwards;
      }
    }
  }
  &__connect {
    height: 100%;
  }
  &__content {
    display: grid;
    height: 100%;
    width: 100%;
    align-content: center;
    justify-content: center;
    height: inherit;
    padding-top: 32px;
  }
  &__connecting {
    opacity: 0;
    h2 {
      font-size: 14px;
      text-align: center;
      font-weight: 300;
      color: #ccc;
    }
    &.animate {
      animation: blink 3s ease infinite;
    }
    &.hidden {
      display: none;
    }
  }
  &__error {
    display: grid;
    align-content: center;
    justify-content: center;
    height: inherit;
    h4 {
      font-size: 1.1rem;
      text-align: center;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }
  &__quotes {
    margin-top: 12px;
    padding: 0px 20px;
    animation: fadeInUp 2s ease;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    color: #888;
  }
}
</style>
