<template>
  <div class="error">
    <ExclamationIcon class="error__icon" />
    <p class="error__desc">{{errorDescription}}</p>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ExclamationIcon from "./icons/ExclamationIcon";

export default {
  name: "ErrorNotification",
  computed: {
    ...mapGetters(["getError"]),
    errorDescription() {
      return this.getError.description || this.$store.state.translations.errorConnectingDataSource;
    },
  },
  components: { ExclamationIcon },
};
</script>
<style lang="scss" scoped>
.error {
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
  background: #F5292214;
  width: 100%;
  min-height: 40px;
  border-radius: 8px;
  padding: 8px 12px 8px 8px;
  box-sizing: border-box;
  &__desc {
    color: #F52922;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    padding-left: 4px;
  }
  &__icon {
    justify-self: center;
  }
}
</style>
