export default {
  SET_SOURCES: 'SET_SOURCES',
  SET_SELECTED_SOURCE: 'SET_SELECTED_SOURCE',
  SET_ERROR: 'SET_ERROR',
  SET_CONNECT_LOGO_POSITION: 'SET_CONNECT_LOGO_POSITION',
  SET_CONNECTED_SOURCES: 'SET_CONNECTED_SOURCES',
  SET_SUGGESTED_SOURCES: 'SET_SUGGESTED_SOURCES',
  SET_SEARCH_INPUT_FOCUSED: 'SET_SEARCH_INPUT_FOCUSED',
  SET_AUTH: 'SET_AUTH',
  SET_CLOSE_MODAL_ENABLED: 'SET_CLOSE_MODAL_ENABLED',
  SET_LOGIN_CREDENTIALS: 'SET_LOGIN_CREDENTIALS',
  SET_APP_UUID: 'SET_APP_UUID',
  SET_FETCH_UUID: 'SET_FETCH_UUID',
  SET_COUNTRY_MODAL: 'SET_COUNTRY_MODAL',
  SET_COUNTRY_SELECTED: 'SET_COUNTRY_SELECTED',
  SET_CODE_MODAL: 'SET_CODE_MODAL',
  SET_REFRESHED_UUID: 'SET_REFRESHED_UUID',
  SET_FACEBOOK_MODAL: 'SET_FACEBOOK_MODAL',
};
