<template>
  <svg
    width="13"
    height="7"
    viewBox="0 0 13 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class=""
  >
    <path
      d="M1 1L6.5 6L12 1"
      stroke="black"
      stroke-linecap="round"
    />
  </svg>
</template>
