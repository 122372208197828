<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable-next-line -->
    <path d="M12.2654 6.84H6.91344V12.24H5.35344V6.84H0.0254377V5.4H5.35344V0H6.91344V5.4H12.2654V6.84Z" fill="#121212"></path>
  </svg>
</template>
<style lang="scss" scoped>
svg {
  width: 13px;
  height: 13px;
  padding: 12px;
  cursor: pointer;
  border-radius: 999px;
  transition: $transition;
  &:hover {
    background: #f5f5f5;
    // transform: scale(1.1);
  }
}
</style>
