<template>
  <div class="page page--error">
    <Header :title="'Rollee Ride'" :hasClose="false" />
    <div class="page__content">
      <h1>Error</h1>
      <p>Make sure that you have correct link to application to use.</p>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header';

export default {
  name: 'Error',
  components: {
    Header,
  },
};

</script>
<style>

</style>
