<template>
  <div class="page page--contact">
    <Header :hasClose="true" :hasBack="true" @goBack="goBack" />
    <div class="page__content">
      <div v-if="!submited">
        <div class="input__wrapper">
          <input
            type="text"
            ref="companyName"
            v-model="companyName"
            :placeholder="enterCompanyName" />
        </div>
        <button
          class="button--block"
          :disabled="disabledSubmit"
          :style="`background: ${getConfig.button_backcolor};
                   color: ${getConfig.button_forecolor}`"
          @click="submit()">
            <Translate :translate="'submit'" />
        </button>
      </div>
      <div v-else>
        <h3>
          <Translate :translate="'getBackYouSoon'" />
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Header from '@/components/Header';

export default {
  name: 'Contact',
  components: {
    Header,
  },
  data() {
    return {
      companyName: '',
      submited: false,
    };
  },
  computed: {
    disabledSubmit() {
      return this.companyName === '';
    },
    enterCompanyName() {
      return this.$store.getters.translations.enterCompanyName;
    },
    ...mapGetters(['getConfig']),
  },
  mounted() {
    setTimeout(() => {
      this.$refs.companyName.focus();
    }, 1000);
  },
  methods: {
    ...mapActions(['sendEmail']),
    submit() {
      this.sendEmail(this.companyName).then((res) => {
        if (res) {
          this.submited = true;
          if (this.$store.state.config.redirect) {
            setTimeout(() => {
              window.location.href = this.$store.state.config.redirect;
            }, 2000);
          }
        }
      });
    },
    goBack() {
      this.$router.push({
        name: 'Search',
      });
    },
  },
};
</script>

<style>

</style>
