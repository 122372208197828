import { isNil } from 'lodash';
import Data from '../api/data';
import types from './mutationTypes';

export default {

  async getSources({ commit, state }) {
    try {
      const result = await Data.getSources(state.config);
      if (isNil(result)) {
        throw new Error('getSourceError');
      }
      commit(types.SET_SOURCES, result);
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        description: error.error,
      });
    }
  },
  async sendEmail({ commit, state }, payload) {
    try {
      const result = await Data.sendEmail(state.config, payload);
      if (isNil(result)) {
        throw new Error('sendEmailError');
      }
      return true;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        description: error.error,
      });
      return undefined;
    }
  },
  async connectToSource({ commit, state }, source) {
    try {
      const result = await Data.connectToSource(state.config,
        source, state.loginCredentials);
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async sendConfirmationCode({ commit, state }, code) {
    try {
      const result = await Data.sendConfirmationCode(state.config, code, state.fetchUUID);
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async getConnectedSources({ commit, state }) {
    try {
      const result = await Data.getConnectedSources(state.config);
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      if (state.config.product === 'connect') {
        commit(types.SET_CONNECTED_SOURCES, result['sources-connected']);
      } else {
        state.connectedSources.push(state.selectedSource);
      }
      commit(types.SET_SUGGESTED_SOURCES, result['sources-suggested']);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async loopForStatus({ commit, state }, fetchUUID) {
    try {
      const result = await Data.loopForStatus(state.config, fetchUUID);
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },

};
