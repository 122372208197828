<template>
  <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    <circle class="circle" cx="26" cy="26" r="25" fill="none"></circle>
    <path class="check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
  </svg>
</template>
<style lang="scss">
.checkmark {
    width: 106px;
    height: 106px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 24px auto;
    box-shadow: inset 0px 0px 0px #00d3a1;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    animation-delay: 1s;
    .circle {
      stroke-dasharray: 216;
      stroke-dashoffset: 216;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #00D3A1;
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      animation-delay: 1.2s;
    }
    .check {
      transform-origin: 50% 50%;
      stroke-dasharray: 98;
      stroke-dashoffset: 98;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
      animation-delay: 1.4s;
    }
}
</style>
