import http from './http';

const getPath = (config) => {
  if (config.product === 'connect') {
    if (process.env.VUE_APP_API_CONNECT_PATH) {
      return process.env.VUE_APP_API_CONNECT_PATH;
    }
    return window.location.origin;
  }
  return '';
};

const getUUID = () => {
  const path = window.location.pathname.split('/');
  if (path[4]?.length < 36) {
    return null;
  }
  return path[4];
};

const getSources = async (config) => {
  const UUID = getUUID();
  const DOMAIN_PATH = getPath(config);
  const response = await http.get(`${DOMAIN_PATH}${config.connect_source_all}?user=${UUID}`);
  const result = response.data;

  return result;
};

const getConnectedSources = async (config) => {
  const DOMAIN_PATH = getPath(config);
  const response = await http.get(`${DOMAIN_PATH}${config.connect_source_all}?user=${config.uuid}&connected=1&suggested=1`);
  const result = response.data;
  return result;
};

const connectToSource = async (config, source, credentials) => {
  const AUTH_TYPE = '';
  const formData = new FormData();
  formData.append('auth-password', credentials.password);
  const USERNAME = encodeURIComponent(credentials.username);
  const DOMAIN_PATH = getPath(config);
  let response;
  if (config.product === 'connect') {
    response = await http.post(`${DOMAIN_PATH}${config.connect_fetch_new}?user=${config.uuid}&auth-user=${USERNAME}&auth-type=${AUTH_TYPE}&source=${source.url}&no-poll=true`, formData);
  } else {
    response = await http.get(`${DOMAIN_PATH}${config.connect_fetch_new}?user=${config.uuid}&auth-user=${USERNAME}&auth-type=${AUTH_TYPE}&source=${source.url}&no-poll=true`, formData);
  }
  const result = response.data;

  return result;
};

const loopForStatus = async (config, fetchUUID) => {
  const DOMAIN_PATH = getPath(config);
  const response = await http.get(`${DOMAIN_PATH}/usr/0.3/fetch-status/${fetchUUID}`);
  const result = response.data;

  return result;
};

const sendConfirmationCode = async (config, code, fetchUUID) => {
  const DOMAIN_PATH = getPath(config);
  const response = await http.get(`${DOMAIN_PATH}${config.connect_fetch_set}?fetch=${fetchUUID}&auth-code=${code}&connected=1&suggested=1`);
  const result = response.data;

  return result;
};

const sendEmail = async (config, payload) => {
  const BASE_URL = process.env.VUE_APP_BASE_URL;
  const USER_ID = config.uuid;
  const DOMAIN_PATH = getPath(config);
  const response = await http.post(`${DOMAIN_PATH}${BASE_URL}${USER_ID}/notify-unknown-ds/${encodeURI(payload)}`);
  const result = response.data;
  return result;
};

const analytics = async (payload) => {
  const DOMAIN_PATH = process.env.VUE_APP_ANALYTICS_HOST;
  const response = await http.post(`${DOMAIN_PATH}/${payload.type}`, payload.data);
  const result = response.data;
  return result;
};

export default {
  getSources,
  getConnectedSources,
  connectToSource,
  sendConfirmationCode,
  sendEmail,
  loopForStatus,
  analytics,
};
