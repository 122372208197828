<template>
  <div class="modal page page--modal" v-if="getFacebookModal">
    <Header :hasBack="true" @goBack="goBack" />
    <div class="page__content">
      <h2><Translate :translate="'facebookNotSupported'" /></h2>
      <p><Translate :translate="'connectToOtherAccounts'" /></p>
    </div>
    <Footer>
      <div class="modal__footer">
        <button class="button--secondary" @click="goBack()">
          <Translate :translate="'backToSearch'" />
        </button>
        <button class="button--primary" @click="closeModalYes()">
          <Translate :translate="'close'" />
        </button>
      </div>
    </Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from './Header';
import Footer from './Footer';

export default {
  name: "FacebookModal",
  components: { Footer, Header },
  computed: {
    ...mapGetters(['getFacebookModal']),
  },
  methods: {
    closeModalYes() {
      // eslint-disable-next-line
      window.top.postMessage('closeSession');
      this.$router.push({ name: 'Home', params: { id: this.$route.params.id } });
      this.$store.commit('SET_FACEBOOK_MODAL', false);
    },
    goBack() {
      this.$router.push({ name: 'Search' });
      setTimeout(() => {
        this.$store.commit('SET_FACEBOOK_MODAL', false);
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99;
  grid-template-rows: $header-height auto 140px;
  &__footer {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 12px;
  }
}
</style>
