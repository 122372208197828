export default {
  common: (locale) => {
    switch (locale) {
      case 'fr':
        return import(/* webpackChunkName: "i18n.common.fr" */'./common.fr');
      default:
        return import(/* webpackChunkName: "i18n.common" */'./common');
    }
  },
};
