<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      stroke="none">
      <path d="M2480 4303 c-560 -16 -1119 -212 -1565 -547 -382 -287 -696 -685
      -879 -1113 l-35 -83 35 -82 c350 -820 1098 -1427 1983 -1609 325 -67 757 -66
      1087 1 880 180 1628 788 1978 1608 l35 82 -35 83 c-182 427 -485 813 -865
      1103 -495 376 -1114 575 -1739 557z m281 -599 c246 -46 448 -152 619 -324 222
      -221 340 -506 340 -820 0 -314 -118 -599 -340 -820 -221 -222 -506 -340 -820
      -340 -314 0 -599 118 -820 340 -222 221 -340 506 -340 820 0 314 118 599 340
      820 220 221 499 338 811 339 77 1 160 -5 210 -15z"/>
      <path d="M2435 3246 c-153 -31 -259 -88 -370 -200 -136 -137 -199 -291 -199
      -486 0 -198 63 -350 204 -490 140 -141 292 -204 490 -204 198 0 350 63 490
      204 141 140 204 292 204 490 0 198 -63 350 -204 490 -131 132 -273 195 -460
      205 -51 3 -113 -1 -155 -9z"/>
      </g>
  </svg>
</template>
