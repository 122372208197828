<template>
  <div class="page page--success">
    <Header :hasClose="true" />
    <div class="success__wrapper">
      <SuccessIcon class="success__icon" />
      <h1 class="success__title">
        <Translate :translate="'successTitle'" />
      </h1>
    </div>
  </div>
</template>

<script>
import SuccessIcon from '@/components/icons/SuccessIcon';
import Header from '@/components/Header';

export default {
  name: 'Success',
  components: {
    SuccessIcon,
    Header,
  },
  mounted() {
    this.$store.state.connectedSources = [];
    this.$store.state.selectedSource = undefined;
    window.top.postMessage(`{"onCompleted": true, "userUUID": "${this.$route.params.id}"}`, "*");
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(`{"onCompleted": true, "userUUID": "${this.$route.params.id}"}`);
    } else {
      window.top.postMessage(`{"onCompleted": true, "userUUID": "${this.$route.params.id}"}`, "*");
    }
    if (this.$store.state.config.redirect) {
      setTimeout(() => {
        window.location.href = this.$store.state.config.redirect;
      }, 3000);
    }
  },
};
</script>
