<template>
  <div class="page page--login">
    <Header :hasClose="true" :hasBack="true" @goBack="goBack" />
    <div class="page__content" v-if="getSelectedSource">
      <div class="login__source">
        <div class="page__list-icon">
          <img
            v-if="getAvatar(getSelectedSource)"
            ref="sourceLogo"
            :src="getAvatar(getSelectedSource)" />
          <div v-else class="page__list-default"></div>
        </div>
        <div class="login__source-name page__list-name">
          <h2>{{ getSelectedSource.name }}</h2>
        </div>
      </div>
      <ErrorNotification v-if="getError" />
      <div class="login__form">
        <div class="login__form-title">
          <h2><Translate :translate="'logInTo'" /> {{ getSelectedSource.name }}</h2>
        </div>
        <div class="login__form-input" v-if="showDefaultField">
          <div class="input__wrapper">
            <textarea
              @keydown.enter.prevent="login"
              v-model="defaultField"
              data-gramm="false"
              :placeholder="defaultPlaceholder"
              rows="0"></textarea>
          </div>
        </div>
        <div class="login__form-input" v-if="showEmailField">
          <div class="input__wrapper">
            <textarea
              @keydown.enter.prevent="login"
              v-model="email"
              data-gramm="false"
              :placeholder="emailPlaceholder"
              rows="0"></textarea>
          </div>
        </div>
        <div class="login__form-input" v-if="showEmailAndPhoneField">
          <div class="input__wrapper">
            <textarea
              @keydown.enter.prevent="login"
              v-model="emailPhone"
              data-gramm="false"
              :placeholder="emailPhonePlaceholder"
              rows="0"></textarea>
          </div>
        </div>
        <div class="login__form-input" v-if="showUsernameField">
          <div class="input__wrapper">
            <textarea
              @keydown.enter.prevent="login"
              v-model="username"
              data-gramm="false"
              :placeholder="usernamePlaceholder"
              rows="0"></textarea>
          </div>
        </div>
        <div class="login__form-input" v-if="showPhoneField">
          <div class="input__wrapper--two-fields">
            <div class="input__wrapper" @click="openCountryModal()">
              <div class="input__country">
                <p>{{ getCountrySelected?.flag }} {{ getCountrySelected?.dial_code }}</p>
                <ArrowDown />
              </div>
            </div>
            <div class="input__wrapper">
              <div class="input__phone">
                <input type="tel"
                  v-model="phone"
                  :placeholder="phonePlaceholder"
                  @keydown="allowNumbersOnly($event)">
              </div>
            </div>
          </div>
        </div>
        <div class="login__form-input" v-if="showPasswordField">
          <div class="input__wrapper input__wrapper--has-icon-right">
            <textarea
              @keydown.enter.prevent="login"
              class="login__form-password"
              :class="{ 'show-password': passwordShow }"
              v-model="password"
              data-gramm="false"
              :placeholder="passwordPlaceholder"
              rows="0"></textarea>
            <VisibleIcon
              class="input__icon"
              :class="{'input--disabled': password.length === 0}"
              @click="passwordShow = !passwordShow"
            />
          </div>
        </div>
        <div class="login__form-button">
          <button
            class="button--block"
            :style="`background: ${getConfig.cta_button_backcolor};
                   color: ${getConfig.cta_button_forecolor}`"
            @click="login"
            :disabled="!continueEnabled">
            <Translate :translate="'continue'"/>
          </button>
        </div>
        <br/>
        <div class="login__form-button" v-if="getSelectedSource.url === 'heetch.com'">
          <button class="button--block button--facebook" @click="openFacebookModal()">
            <FacebookIcon />
            <Translate :translate="'logInWithFacebook'"/>
          </button>
        </div>
      </div>
    </div>
    <Footer>
      <div class="login__footer">
        <h5><Translate :translate="'secureAccess'" /></h5>
        <p><Translate :translate="'dataProtection'" /></p>
      </div>
    </Footer>
    <CountryList v-if="getCountryModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '../components/Header';
import Footer from '../components/Footer';
import VisibleIcon from '../components/icons/VisibleIcon';
import CountryList from '../components/CountryList';
import ArrowDown from '../components/icons/ArrowDown';
import ErrorNotification from '../components/ErrorNotification';
import FacebookIcon from '../components/icons/FacebookIcon';

export default {
  components: {
    Header,
    Footer,
    VisibleIcon,
    CountryList,
    ArrowDown,
    ErrorNotification,
    FacebookIcon,
  },
  name: 'Login',
  data() {
    return {
      passwordShow: false,
      passwordSwitchEnabled: false,
      email: this.$store.state.config.product === 'demo' ? 'demo@demo.com' : '',
      username: this.$store.state.config.product === 'demo' ? 'demo' : '',
      password: this.$store.state.config.product === 'demo' ? 'demo' : '',
      defaultField: this.$store.state.config.product === 'demo' ? 'demo' : '',
      phone: '',
      emailPhone: '',
    };
  },
  computed: {
    ...mapGetters(['getSelectedSource', 'getCountryModal', 'getCountrySelected', 'getError', 'getCountries', 'getConfig']),
    continueEnabled() {
      if (this.showEmailField) {
        return this.validateEmail(this.email) && this.password.length > 0;
      }
      if (this.showUsernameField) {
        return this.validateUser(this.username) && this.password.length > 0;
      }
      if (this.showPhoneField) {
        return this.validatePhone(this.phone) && this.password.length > 0;
      }
      if (this.showEmailAndPhoneField) {
        return this.validateEmailPhone(this.emailPhone) && this.password.length > 0;
      }
      if (this.showDefaultField) {
        return this.validateEmailPhone(this.defaultField) && this.password.length > 0;
      }
      return true;
    },
    passwordPlaceholder() {
      return this.$store.state.translations.password;
    },
    emailPlaceholder() {
      return this.$store.state.translations.email;
    },
    defaultPlaceholder() {
      return this.$store.state.translations.defaultLogin;
    },
    emailPhonePlaceholder() {
      return this.$store.state.translations.emailPhone;
    },
    usernamePlaceholder() {
      return this.$store.state.translations.username;
    },
    phonePlaceholder() {
      return this.$store.state.translations.phone;
    },
    showUsernameField() {
      const loginFields = this.getSelectedSource['login-fields'];
      if (loginFields === undefined || loginFields === null) {
        return false;
      }
      return loginFields.find((field) => field.field === 'username') !== undefined;
    },
    showEmailField() {
      const loginFields = this.getSelectedSource['login-fields'];
      if (loginFields === undefined || loginFields === null) {
        return false;
      }
      return loginFields.find((field) => field.field === 'email') !== undefined;
    },
    showEmailAndPhoneField() {
      const loginFields = this.getSelectedSource['login-fields'];
      if (loginFields === undefined || loginFields === null) {
        return false;
      }
      return loginFields.find((field) => field.field === 'emailPhone') !== undefined;
    },
    showPasswordField() {
      const loginFields = this.getSelectedSource['login-fields'];
      if (loginFields === undefined || loginFields === null) {
        return true;
      }
      return loginFields.find((field) => field.field === 'password') !== undefined;
    },
    showPhoneField() {
      const loginFields = this.getSelectedSource['login-fields'];
      if (loginFields === undefined || loginFields === null) {
        return false;
      }
      return loginFields.find((field) => field.field === 'phone') !== undefined;
    },
    showDefaultField() {
      const loginFields = this.getSelectedSource['login-fields'];
      if (loginFields !== null && loginFields !== undefined) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.$store.commit('SET_COUNTRY_SELECTED', this.$store.state.dialCodes[0]);
    this.setDefaultCountry();
  },
  methods: {
    setDefaultCountry() {
      // eslint-disable-next-line
      const country = this.getCountries.find((countries) => countries.dial_code === this.$store.state.config.phone_code);
      this.$store.commit('SET_COUNTRY_SELECTED', country);
    },
    goBack() {
      this.$router.push({ name: 'Search' });
    },
    openCountryModal() {
      this.$store.commit('SET_COUNTRY_MODAL', true);
    },
    openFacebookModal() {
      this.$store.commit('SET_FACEBOOK_MODAL', true);
    },
    allowNumbersOnly(event) {
      if (event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 13) {
        return;
      }
      if (event.metaKey && event.keyCode === 86) {
        return;
      }
      if (event.keyCode === 86 && event.ctrlKey) {
        return;
      }
      if (event.keyCode < 48 || event.keyCode > 57) {
        event.preventDefault();
      }
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },
    validatePhone(phone) {
      return String(phone).match(/^\+?[0-9]{6,15}$/);
    },
    validateUser(username) {
      return String(username).length > 0;
    },
    validateEmailPhone(emailPhone) {
      return String(emailPhone).length > 0;
    },
    login() {
      if (!this.continueEnabled) {
        return;
      }
      const elPosition = {
        x: this.getOffset(this.$refs.sourceLogo).left,
        y: this.getOffset(this.$refs.sourceLogo).top,
      };
      this.$store.commit('SET_CONNECT_LOGO_POSITION', elPosition);
      this.$store.commit('SET_AUTH', true);

      let username = '';

      if (this.showEmailField) {
        username = this.email;
      } else if (this.showPhoneField) {
        username = `${this.getCountrySelected.code}-${this.phone}`;
      } else if (this.showEmailAndPhoneField) {
        username = this.emailPhone;
      } else if (this.showDefaultField) {
        username = this.defaultField;
      } else {
        username = this.username;
      }

      this.$store.commit('SET_LOGIN_CREDENTIALS', {
        username,
        password: this.password,
      });
      this.$router.push({ name: 'Fetch' });
      this.$store.commit('SET_ERROR', null);
    },
    getAvatar(data) {
      return `${process.env.VUE_APP_API_CONNECT_PATH}${data.avatar}`;
    },
    getOffset(el) {
      const rect = el.getBoundingClientRect();
      const parentRect = document.getElementById('app__wrapper').getBoundingClientRect();

      return {
        left: rect.left - parentRect.left,
        top: rect.top - parentRect.top,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  &__source {
    display: grid;
    grid-template-columns: 60px auto;
    gap: 12px;
    &-name {
      justify-self: left;
      text-align: left;
      margin-bottom: 60px;
      h2 {
        margin-top: 16px;
      }
    }
  }
  &__form {
    &-title {
      h2 {
        text-align: left;
        margin: 32px 0;
        font-weight: normal;
        font-size: 22px;
      }
    }
    &-password {
      // transform: translateY(4px);
      font-family: 'text-security-disc' !important;
      font-size: 18px !important;
      line-height: 22px !important;
      -webkit-text-security: disc !important;
      &::placeholder {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
      }
      &.show-password {
        font-size: 14px !important;
        font-family: 'Poppins', sans-serif !important;
        -webkit-text-security: none !important;
      }
    }
    &-input {
      textarea {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 14px;
        background: transparent;
        border: transparent;
        outline: 0;
        cursor: text;
        resize: none;
        font-family: inherit;
        padding: inherit;
      }
      .input__wrapper {
        min-height: 22px;
      }
    }
  }
  &__footer {
    text-align: center;
    h5 {
      font-size: 11px;
      margin-bottom: 8px;
    }
    p {
      font-size: 10px;
      line-height: 12px;
      color: $footer-description-color;
    }
  }
}
</style>
