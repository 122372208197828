<template>
  <div class="page page--connected">
    <Header :hasClose="true" />
    <div class="page__content page__content--half">
      <div class="page__half">
        <h3 class="accounts__title">
          <Translate :translate="'connectedAccounts'" />
        </h3>
        <div class="accounts__list" v-if="getConnectedSources">
          <ul>
            <li v-for="item in getConnectedSources.slice().reverse()" :key="item.name">
              <img :src="getLogoPath(item.avatar)" :alt="item.name">
              <SuccessIcon class="accounts__success" />
              <h2 class="accounts__list-title">{{item.name}}</h2>
            </li>
          </ul>
        </div>
      </div>
      <div class="page__half">
        <h3 class="accounts__title">
          <Translate :translate="'suggested'" />
        </h3>
        <div class="accounts__list" v-if="getSuggestedSources">
          <ul>
            <li v-for="item in getSuggestedSources.slice(0, 3)" :key="item.name">
              <img :src="getLogoPath(item.avatar)" :alt="item.name">
              <h2 class="accounts__list-title">{{item.name}}</h2>
              <AddIcon class="acconts__list-action" @click="addSuggested(item)" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Footer class="accounts__footer">
      <button @click="gotoSources()"
      :style="`background: ${getConfig.cta_button_backcolor};
                   color: ${getConfig.cta_button_forecolor}`">
        <Translate :translate="'addAccount'" />
      </button>
      <button class="button--secondary" @click="gotoSuccess()">
        <Translate :translate="'done'" />
      </button>
    </Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import AddIcon from '@/components/icons/AddIcon';
import SuccessIcon from '../components/icons/SuccessIcon';

export default {
  name: 'Fetch',
  components: {
    Header,
    Footer,
    AddIcon,
    SuccessIcon,
  },
  computed: {
    ...mapGetters(['getConnectedSources', 'getSuggestedSources', 'sources', 'getConfig']),
  },
  methods: {
    gotoSources() {
      this.$router.push({ name: 'Search', params: { searchFocus: true } });
    },
    gotoSuccess() {
      this.$router.push({ name: 'Success' });
    },
    addSuggested(item) {
      this.$store.commit('SET_SELECTED_SOURCE', item);
      this.$router.push({ name: 'Login' });
    },
    getLogoPath(path) {
      return `${path}`;
    },
  },
  async mounted() {
    const CURRENT_ROUTE = this.$route;
    this.$store.commit('SET_SEARCH_INPUT_FOCUSED', false);
    this.$store.commit('SET_FETCH_UUID', null);
    this.$store.commit('SET_REFRESHED_UUID', null);
    if (this.getConnectedSources.length === 0) {
      await this.$store.dispatch('getConnectedSources');
      if (this.getConnectedSources === null || this.getConnectedSources.length === 0) {
        // eslint-disable-next-line
        this.$router.push({ name: 'Home', params: { id: CURRENT_ROUTE.params.id }, query: CURRENT_ROUTE.query });
      }
    } else {
      await this.$store.dispatch('getConnectedSources');
    }
  },
};
</script>

<style lang="scss" scoped>
.accounts {
  &__title {
    font-size: 19px;
    font-weight: normal;
    margin: 32px 0;
  }

  &__list {
    margin-top: 20px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        position: relative;
        margin-bottom: 16px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 999px;
        }
        .accounts__success {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          left: 44px;
          right: unset;
          margin: unset;
          stroke-width: 6px;
        }
      }
    }

    &-title {
      display: inline;
      vertical-align: top;
      line-height: 60px;
      font-size: 23px;
      font-weight: normal;
      margin-left: 16px;
    }
    svg {
      position: absolute;
      right: 0;
      top: 14px;
    }
  }
  &__footer {
    padding-top: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    gap: 12px;
    button {
      font-size: 14px;
    }
  }
}
</style>
