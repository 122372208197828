<template>
  <div class="page page--terms">
    <Header :hasClose="true" />
    <div class="page__content">
      <div class="terms__info">
        <TermsIcon class="info" />
        <RolleeIcon class="logo" />
      </div>
      <h4 class="page__title"><Translate :translate="'termsPageTitle'" /></h4>
      <div class="page__details">
        <details v-for="item in termsQA" :key="item.title">
          <summary>
            <Translate :translate="item.title" />
            <ArrowIcon />
          </summary>
          <p class="page__details-content"><Translate :translate="item.description" /></p>
        </details>
      </div>
    </div>
    <Footer>
      <div class="page__agreement">
        <Switch @checked="agreementChecked" />
        <h5 class="page__agreement-text">
          <Translate :translate="'termsFooterToggleAccept'" />
          <a :href="termsUrl" target="_blank" class="page__agreement-link">
            <Translate :translate="'termsFooterToggleAcceptLink'" />
          </a>
        </h5>
      </div>
      <button
        class="button--block"
        :disabled="!userCheckedAgreement"
        :style="`background: ${getConfig.cta_button_backcolor};
                 color: ${getConfig.cta_button_forecolor}`"
        @click="goNext()">
        <Translate :translate="'termsFooterAllow'" />
      </button>
      <p class="page__privacy-policy">
        <Translate :translate="'termsPrivacyPolicyDescription_1'" />
        {{ clientName }}
        <Translate :translate="'termsPrivacyPolicyDescription_2'" />&nbsp;
        <a :href="privacyUrl" target="_blank"><Translate :translate="'termsPrivacyPolicy'" /></a>
      </p>
    </Footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Switch from "@/components/Switch";
import TermsIcon from '@/components/icons/TermsIcon';
import ArrowIcon from '@/components/icons/ArrowIcon';
import RolleeIcon from '@/components/icons/RolleeIcon';

export default {
  components: {
    Header,
    Switch,
    Footer,
    TermsIcon,
    ArrowIcon,
    RolleeIcon,
  },
  data() {
    return {
      userCheckedAgreement: false,
      termsUrl: this.$store.state.translations.termsOfUseUrl,
      privacyUrl: this.$store.state.translations.privacyPolicyUrl,
      termsQA: [
        {
          title: 'termsDetailsHowItWorks',
          description: 'termsDetailsHowItWorksDescription',
        },
        {
          title: 'termsDetailsDataProtection',
          description: 'termsDetailsDataProtectionDescription',
        },
      ],
    };
  },
  computed: {
    clientName() {
      return this.$store.state.config.partner_title;
    },
    ...mapGetters(['getConfig', 'getSelectedSource']),
  },
  methods: {
    agreementChecked(checked) {
      if (!this.$store.state.config.product === 'demo') {
        this.$gtag.event('terms', { name: 'checked', value: checked });
      }
      this.userCheckedAgreement = checked;
    },
    goNext() {
      if (window.CONFIG.partner_name === 'rodeo') {
        window.top.postMessage('Home', "*");
      }
      if (this.getSelectedSource) {
        this.$router.push({ name: 'Login' });
      } else {
        this.$router.push({ name: 'Search' });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.terms {
  &__info {
    position: relative;
    .logo {
      width: 32px;
      height: 32px;
      position: absolute;
      left: 50%;
      top: 36px;
      transform: translate(-50%, 0)
    }
  }
}
</style>
