<template>
  <svg class="icon icon--close" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.69434"
      y="0.128662"
      width="15.4449"
      height="1.34303"
      transform="rotate(45 1.69434 0.128662)"
      fill="#121212">
    </rect>
    <rect
      x="12.6152"
      y="0.949707"
      width="15.4449"
      height="1.34303"
      transform="rotate(135 12.6152 0.949707)"
      fill="#121212">
    </rect>
  </svg>
</template>
<style scoped lang="scss">
.icon {
  cursor: pointer;
  &--close {
    padding: 27px 14px;
  }
}
</style>
