import { createApp } from 'vue/dist/vue.esm-bundler';
import axios from 'axios';
import VueAxios from 'vue-axios';
import _ from 'lodash';
import router from './router';
import i18n from './i18n';
import store from './store';
import App from './App';
import http from './api/http';

import _translate from './components/_translate';

(async () => {
  const app = createApp(App);

  let uuid = window.location.pathname;
  const BASE_URL = process.env.VUE_APP_BASE_URL;

  if (uuid.indexOf(BASE_URL) === 0) {
    uuid = uuid.substring(BASE_URL.length);
    const slash = uuid.indexOf('/');
    if (slash > 0) {
      uuid = uuid.substring(0, slash);
    }
  }
  const response = await http.get(`${process.env.VUE_APP_CONF_BASE}conf.js?user=${uuid}`);
  const conf = document.createElement("script");
  conf.type = "text/javascript";
  conf.text = response.data;
  document.getElementsByTagName("head")[0].appendChild(conf);

  if (window.CONFIG) {
    store.state.config = _.merge(store.state.config, window.CONFIG);
    store.state.externalConfig = true;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const Qparams = Object.fromEntries(urlParams.entries());

  if (Qparams.remove_close_button === 'true') {
    store.state.config.remove_close_button = Qparams.remove_close_button;
  }
  if (Qparams.skip_datasources_page === 'true') {
    store.state.config.skip_datasources_page = Qparams.skip_datasources_page;
  }

  window.onmessage = (e) => {
    if (e.data.action === 'setConfig') {
      const configFromPostMessage = JSON.parse(e.data.value);
      store.state.config = _.merge(store.state.config, configFromPostMessage);
    }
    if (e.data.action === 'setPage') {
      router.push({ name: e.data.value, params: { id: 'demo' } });
    }
    if (e.data.action === 'setDatasources') {
      store.state.sources.sources = JSON.parse(e.data.value);
    }
  };

  const translations = (await i18n.common(store.state.config.lang)).default;
  store.state.translations = translations;

  app.use('$_', _);
  app.use(store);
  app.use(router);
  app.use(VueAxios, axios);
  app.component('Translate', _translate);
  app.mount('#app');
})();
